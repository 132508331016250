<template>
	<div>
		<div class="cam">
			<div class="error" v-if="error">
				<div class="uk-padding">
					<div uk-icon="icon: warning; ratio: 2"></div>
					<span>{{ $store.strings.camera_error }}</span>
					<span class="error">{{ error }}</span>
				</div>
			</div>
			<div class="loader" v-if="starting">
				<div class="uk-padding">
					<div uk-icon="icon: camera; ratio: 2"></div>
					<span>{{ $store.strings.camera_starting }}</span>
				</div>
			</div>
			<web-cam ref="webcam" :device-id="deviceId" @started="onStarted" @stopped="onStopped" @error="onError" @cameras="onCameras" @camera-change="onCameraChange" />
			<div class="cam__overlay" :class="$store.request.options.flip ? 'cam__overlay--flipped' : ''" v-if="overlay">
				<img :src="overlay" alt="Overlay" />
			</div>
			<div class="cam__controls" v-if="flippable || devices.length > 1">
				<button class="cam__change_btn" @click="changeCamera()" v-if="devices.length > 1"></button>
				<button class="cam__flip_btn" @click="flipOverlay()" v-if="flippable"></button>
			</div>
		</div>
	</div>
</template>

<script>
import { WebCam } from 'vue-web-cam'
export default {
	name: 'Cam',
	props: {
		flippable: {
			type: Boolean,
			default: false,
		},
		ready: Function,
		overlay: {
			type: String,
			default: null,
		},
	},
	components: {
		WebCam,
	},
	data() {
		return {
			img: null,
			camera: null,
			camIndex: 0,
			deviceId: null,
			devices: [],
			starting: true,
			error: false,
		}
	},
	computed: {
		device: function() {
			return this.devices.find(n => n.deviceId === this.deviceId)
		},
	},
	watch: {
		camera: function(id) {
			this.deviceId = id
		},
		devices: function() {
			// Once we have a list try to find back camera by label
			// else user first cam
			const cam =
				this.devices.find((device, index) => {
					// console.log(`Device ${index} : ${device.label}`)

					// S8 ->
					// Device 0 : camera2 0, facing back
					// Device 1 : camera2 1, facing front

					// iPhone ->
					// Device 0 : Front Camera
					// Device 1 : Back Camera, Rück Kamera

					// TODO: other languages?

					if (
						device.label.toLowerCase().indexOf('back') > -1 ||
						device.label.toLowerCase().indexOf('rück') > -1
					) {
						this.camIndex = index
						return true
					}
				}) || this.devices[0]

			// console.log(`selected device : ${this.devices[this.camIndex].label}`)

			this.camera = cam.deviceId
			this.deviceId = cam.deviceId
		},
	},
	methods: {
		onCapture() {
			this.img = this.$refs.webcam.capture()
			return this.img
		},
		onStarted() {
			this.starting = false
			this.$props.ready()
		},
		onStopped() {
		},
		onStop() {
			this.$refs.webcam.stop()
		},
		onStart() {
			this.$refs.webcam.start()
		},
		onError(error) {
			this.starting = false
			this.error = error
		},
		onCameras(cameras) {
			this.devices = cameras
		},
		onCameraChange(deviceId) {
			this.deviceId = deviceId
			this.camera = deviceId
		},
		changeCamera() {
			// loop over all devices
			let cam = null
			if (this.devices[this.camIndex + 1]) {
				cam = this.devices[this.camIndex + 1]
				this.camIndex++
			} else {
				cam = this.devices[0]
				this.camIndex = 0
			}
			this.camera = cam.deviceId
			this.deviceId = cam.deviceId
		},
		flipOverlay() {
			this.$store.request.options.flip = !this.$store.request.options.flip
		},
	},
}
</script>

<style scoped lang="scss">
.cam {
	width: 100%;
	padding-bottom: 75%;
	position: relative;
	background-color: #fbfbfb;

	video {
		object-fit: cover;
		object-position: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
	}
	.error,
	.loader {
		& > div {
			background: #fbfbfb;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			bottom: -1px;

			span {
				margin-top: 15px;
				font-size: 0.6em;
			}
			span.error {
				font-size: 0.4em;
			}
		}
	}
}

.cam__controls {
	position: absolute;
	@extend .uk-button-group;
	@extend .uk-position-bottom-center;
	@extend .uk-position-small;

	button {
		@extend .uk-button;
		@extend .uk-button-primary;
		background-repeat: no-repeat;
		background-position: center;
		width: 50px;
		height: 40px;
	}
}
.cam__change_btn {
	background-image: url("../assets/change-camera.svg");
}
.cam__flip_btn {
	background-image: url("../assets/flip-camera.svg");
}

.cam__overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	img {
		width: 100%;
	}
}

.cam__overlay--flipped {
	transform: scaleX(-1);
}
</style>
